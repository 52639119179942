/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import {afterEach} from '@/router/afterEach'
import {beforeEach} from '@/router/beforeEach'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [

    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('../layouts/main/Main.vue'),
      meta: {requiresAuth: true},
      children: [
        // =============================================================================
        // HOME Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import('../views/admin/Home.vue'),
          meta: {
            pageTitle: 'داشبورد',
            pageTitleI18n: 'dashboard'
          }
        },

        // =============================================================================
        // PROFILE PAGES LAYOUTS
        // =============================================================================
        {
          path: '/profile',
          component: () => import('../views/admin/profile/index.vue'),
          children: [
            {
              path: '/',
              component: () => import('../views/admin/profile/profile/userProfile.vue'),
              meta: {
                pageTitle: 'پروفایل',
                pageTitleI18n: 'profile.title'
                // rule: 'editor'
              },
              name: 'Profile'
            },
            // {
            //   path: 'transactions',
            //   name: 'profileTransactions',
            //   component: () => import('../views/admin/profile/transactions/profileTransactions.vue'),
            //   meta: {
            //     pageTitle: 'پروفایل',
            //     pageTitleI18n: 'profile.title'
            //     // rule: 'editor'
            //   }
            // },
            // {
            //   path: 'addresses',
            //   name: 'profileAddresses',
            //   component: () => import('../views/admin/profile/addresses/profileAddresses.vue'),
            //   meta: {
            //     pageTitle: 'پروفایل',
            //     pageTitleI18n: 'profile.title'
            //     // rule: 'editor'
            //   }
            // },
            {
              path: 'received-events',
              name: 'profileReceivedEvents',
              component: () => import('../views/admin/profile/events/profileEvents.vue'),
              meta: {
                pageTitle: 'پیام های دریافتی',
                pageTitleI18n: 'profile.events.receive.title'
                // rule: 'editor'
              }
            },
            {
              path: 'sent-events',
              name: 'profileSentEvents',
              component: () => import('../views/admin/profile/events/profileEvents.vue'),
              meta: {
                pageTitle: 'پیام های ارسالی',
                pageTitleI18n: 'profile.events.send.title'
                // rule: 'editor'
              }
            },
            // {
            //   path: 'edit',
            //   name: 'editProfile',
            //   component: () => import('../views/admin/profile/edit/editProfile.vue'),
            //   meta: {
            //     pageTitle: 'پروفایل',
            //     pageTitleI18n: 'profile.title'
            //     // rule: 'editor'
            //   }
            // }
          ]
        },

        // =============================================================================
        // EVENTS PAGES LAYOUTS
        // =============================================================================
        {
          path: '/events',
          component: () => import('../views/admin/events/index.vue'),
          children: [
            {
              path: '/',
              name: 'events',
              component: () => import('../views/admin/events/list/events.vue'),
              meta: {
                permission: 'event.show',
                pageTitle: 'پیام ها',
                pageTitleI18n: 'events.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // USERS PAGES LAYOUTS
        // =============================================================================
        {
          path: '/customers',
          component: () => import('../views/admin/customers/index.vue'),
          children: [
            {
              path: '/',
              name: 'customers',
              component: () => import('../views/admin/customers/list/usersList.vue'),
              meta: {
                permission: 'user.show',
                pageTitle: 'مشتریان',
                pageTitleI18n: 'users.customers.list.title'
                // rule: 'editor'
              }
            },
            {
              path: 'trash',
              name: 'customersTrash',
              component: () => import('../views/admin/customers/trash/usersTrash.vue'),
              meta: {
                permission: 'user.delete',
                pageTitle: 'سطل زباله مشتریان',
                pageTitleI18n: ''
                // rule: 'editor'
              }
            },
            {
              path: 'insert',
              name: 'insertCustomer',
              component: () => import('../views/admin/customers/insert/insertUser.vue'),
              meta: {
                permission: 'user.create',
                pageTitle: 'افزودن مشتری',
                pageTitleI18n: 'users.customers.insert.title'
                // rule: 'editor'
              }
            },
            {
              path: ':userId/season-schedules',
              name: 'customerSeasonSchedulesList',
              component: () => import('../views/admin/customers/seasonSchedules/seasonSchedulesList.vue'),
              meta: {
                permission: 'season_scheduler.show',
                pageTitle: '',
                pageTitleI18n: 'users.seasonSchedules.title'
                // rule: 'editor'
              }
            },
            // {
            //   path: ':id',
            //   component: () => import('../views/admin/users/user/index.vue'),
            //   children: [
            //     {
            //       path: '/',
            //       component: () => import('../views/admin/users/user/profile/userProfile'),
            //       name: 'user',
            //       meta: {
            //         permission: 'user.show',
            //         pageTitle: 'شخص',
            //         pageTitleI18n: 'users.customers.profile.title'
            //         // rule: 'editor'
            //       }
            //     },
            //     {
            //       path: 'transactions',
            //       name: 'userTransactions',
            //       component: () => import('../views/admin/users/user/transactions/userTransactions.vue'),
            //       meta: {
            //         permission: 'user.show',
            //         pageTitle: 'گردش مالی شخص',
            //         pageTitleI18n: ''
            //         // rule: 'editor'
            //       }
            //     },
            //     {
            //       path: 'transactions/:docId',
            //       name: 'userTransaction',
            //       component: () => import('../views/admin/accountancy/documents/document/document.vue'),
            //       meta: {
            //         permission: 'user.show',
            //         pageTitle: 'سند گردش مالی شخص',
            //         pageTitleI18n: ''
            //         // rule: 'editor'
            //       }
            //     },
            //     {
            //       path: 'addresses',
            //       name: 'userAddresses',
            //       component: () => import('../views/admin/users/user/addresses/userAddresses.vue'),
            //       meta: {
            //         permission: 'user.show',
            //         pageTitle: 'شخص',
            //         pageTitleI18n: 'users.customers.profile.title'
            //         // rule: 'editor'
            //       }
            //     },
            //     {
            //       path: 'activities-log',
            //       name: 'userActivitiesLog',
            //       component: () => import('../views/admin/users/user/activitiesLog/userActivitiesLog.vue'),
            //       meta: {
            //         permission: 'user.show',
            //         pageTitle: 'لاگ های شخص',
            //         pageTitleI18n: ''
            //         // rule: 'editor'
            //       }
            //     }
            //   ]
            // },
            // {
            //   path: ':id/edit',
            //   name: 'editUser',
            //   component: () => import('../views/admin/users/edit/editUser.vue'),
            //   meta: {
            //     permission: 'user.update',
            //     pageTitle: ',ویرایش اطلاعات شخص',
            //     pageTitleI18n: 'users.customers.edit.title'
            //     // rule: 'editor'
            //   }
            // }
          ]
        },

        // =============================================================================
        // USERS PAGES LAYOUTS
        // =============================================================================
        {
          path: '/coaches',
          component: () => import('../views/admin/coaches/index.vue'),
          children: [
            {
              path: '/',
              name: 'coaches',
              component: () => import('../views/admin/coaches/list/coachesList.vue'),
              meta: {
                permission: 'coach.show',
                pageTitle: '',
                pageTitleI18n: 'coaches.list.title'
                // rule: 'editor'
              }
            },
            {
              path: 'trash',
              name: 'coachesTrash',
              component: () => import('../views/admin/customers/trash/usersTrash.vue'),
              meta: {
                permission: 'coach.delete',
                pageTitle: '',
                pageTitleI18n: ''
                // rule: 'editor'
              }
            },
            {
              path: 'insert',
              name: 'insertCoach',
              component: () => import('../views/admin/coaches/insert/insertCoach.vue'),
              meta: {
                permission: 'coach.create',
                pageTitle: 'افزودن مشتری',
                pageTitleI18n: 'coaches.insert.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id/classes',
              name: 'coachClasses',
              component: () => import('../views/admin/coaches/classes/coachClassesList.vue'),
              meta: {
                permission: 'coach.show',
                pageTitle: 'افزودن مشتری',
                pageTitleI18n: 'coaches.classes.title'
                // rule: 'editor'
              }
            },
          ]
        },

        // =============================================================================
        // TIME PAGES LAYOUTS
        // =============================================================================
        {
          path: '/times',
          component: () => import('../views/admin/times/index.vue'),
          children: [
            {
              path: '/',
              name: 'times',
              component: () => import('../views/admin/times/list/timesList.vue'),
              meta: {
                permission: 'time.show',
                pageTitle: 'تایم',
                pageTitleI18n: 'times.list.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // SALES ADDONS PAGES LAYOUTS
        // =============================================================================
        {
          path: '/sales-addons',
          component: () => import('../views/admin/sales/addons/index.vue'),
          children: [
            {
              path: '/',
              name: 'salesAddons',
              component: () => import('../views/admin/sales/addons/list/salesAddonsList.vue'),
              meta: {
                permission: 'sale_addon.show',
                pageTitle: 'افزودنی های رزرو',
                pageTitleI18n: 'sales.addons.title'
                // rule: 'editor'
              }
            },
            {
              path: 'trash',
              name: 'saleAddonsTrash',
              component: () => import('../views/admin/sales/addons/trash/saleAddonTrash.vue'),
              meta: {
                permission: 'sale_addon.delete',
                pageTitle: 'سطل افزودنی های رزرو',
                pageTitleI18n: ''
                // rule: 'editor'
              }
            },
          ]
        },

        // =============================================================================
        // COURT PAGES LAYOUTS
        // =============================================================================
        {
          path: '/courts',
          component: () => import('../views/admin/courts/index.vue'),
          children: [
            {
              path: '/',
              name: 'courts',
              component: () => import('../views/admin/courts/list/courtsList.vue'),
              meta: {
                permission: 'court.show',
                pageTitle: 'زمین',
                pageTitleI18n: 'courts.list.title'
                // rule: 'editor'
              }
            },
            {
              path: 'trash',
              name: 'courtsTrash',
              component: () => import('../views/admin/courts/trash/courtsTrash.vue'),
              meta: {
                permission: 'court.delete',
                pageTitle: 'سطل زباله زمین',
                pageTitleI18n: 'courts.trash.title'
                // rule: 'editor'
              }
            },
            {
              path: 'insert',
              name: 'insertCourt',
              component: () => import('../views/admin/courts/insert/insertCourt.vue'),
              meta: {
                permission: 'court.create',
                pageTitle: 'افزودن زمین',
                pageTitleI18n: 'courts.insert.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id/edit',
              name: 'editCourt',
              component: () => import('../views/admin/courts/edit/editCourt.vue'),
              meta: {
                permission: 'court.update',
                pageTitle: ',ویرایش اطلاعات زمین',
                pageTitleI18n: 'courts.edit.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // TERM PAGES LAYOUTS
        // =============================================================================
        {
          path: '/terms',
          component: () => import('../views/admin/courts/index.vue'),
          children: [
            {
              path: '/',
              name: 'terms',
              component: () => import('../views/admin/terms/list/termsList.vue'),
              meta: {
                permission: 'term.show',
                pageTitle: 'سانس',
                pageTitleI18n: 'terms.list.title'
                // rule: 'editor'
              }
            },
            {
              path: 'trash',
              name: 'termsTrash',
              component: () => import('../views/admin/terms/trash/termsTrash.vue'),
              meta: {
                permission: 'term.delete',
                pageTitle: 'سطل زباله سانس',
                pageTitleI18n: 'terms.trash.title'
                // rule: 'editor'
              }
            },
            {
              path: 'insert',
              name: 'insertTerm',
              component: () => import('../views/admin/terms/insert/insertTerm.vue'),
              meta: {
                permission: 'term.create',
                pageTitle: 'افزودن سانس',
                pageTitleI18n: 'terms.insert.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id/edit',
              name: 'editTerm',
              component: () => import('../views/admin/terms/edit/editTerm.vue'),
              meta: {
                permission: 'term.update',
                pageTitle: ',ویرایش اطلاعات سانس',
                pageTitleI18n: 'terms.edit.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // CHARACTER PAGES LAYOUTS
        // =============================================================================
        {
          path: '/characters',
          component: () => import('../views/admin/characters/index.vue'),
          children: [
            {
              path: '/',
              name: 'characters',
              component: () => import('../views/admin/characters/list/charactersList.vue'),
              meta: {
                permission: 'character.show',
                pageTitle: 'گروه مشتریان',
                pageTitleI18n: 'characters.list.title'
                // rule: 'editor'
              }
            },
            {
              path: 'trash',
              name: 'charactersTrash',
              component: () => import('../views/admin/characters/trash/charactersTrash.vue'),
              meta: {
                permission: 'character.delete',
                pageTitle: 'سطل زباله گروه مشتریان',
                pageTitleI18n: ''
                // rule: 'editor'
              }
            },
          ]
        },

        // =============================================================================
        // REFUND PAGES LAYOUTS
        // =============================================================================
        {
          path: '/refunds',
          component: () => import('../views/admin/refunds/index.vue'),
          children: [
            {
              path: '/',
              name: 'refunds',
              component: () => import('../views/admin/refunds/list/refundsList.vue'),
              meta: {
                permission: 'refund_rule.show',
                pageTitle: 'قوانین استرداد وجه',
                pageTitleI18n: 'refunds.list.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // SALES DISCOUNT PAGES LAYOUTS
        // =============================================================================
        {
          path: '/discount-groups',
          component: () => import('../views/admin/sales/discount/index.vue'),
          children: [
            {
              path: '/',
              name: 'discountGroups',
              component: () => import('../views/admin/sales/discount/list/saleDiscountList.vue'),
              meta: {
                permission: 'discount_group.show',
                pageTitle: 'تخفیفات رزرو',
                pageTitleI18n: 'sales.discount.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // SALES CLOSED PAGES LAYOUTS
        // =============================================================================
        {
          path: '/closed-invoice',
          component: () => import('../views/admin/sales/discount/index.vue'),
          children: [
            {
              path: '/',
              name: 'closedInvoices',
              component: () => import('../views/admin/closed/list/closedInvoiceList.vue'),
              meta: {
                permission: 'closed_invoice.show',
                pageTitle: 'تعطیلات',
                pageTitleI18n: 'closed.title'
                // rule: 'editor'
              }
            },
            {
              path: 'insert',
              name: 'insertClosedInvoices',
              component: () => import('../views/admin/closed/calendar/salesClosedCalendar.vue'),
              meta: {
                permission: 'closed_invoice.create',
                pageTitle: 'تعطیلات',
                pageTitleI18n: 'closed.insert.title'
                // rule: 'editor'
              }
            },
            {
              path: 'trash',
              name: 'closedInvoicesTrash',
              component: () => import('../views/admin/closed/trash/closedInvoiceTrash.vue'),
              meta: {
                permission: 'closed_invoice.delete',
                pageTitle: 'سطل اعلامیه تعطیلات',
                pageTitleI18n: ''
                // rule: 'editor'
              }
            },
            {
              path: ':id',
              name: 'closedInvoiceProfile',
              component: () => import('../views/admin/closed/profile/closedInvoiceProfile.vue'),
              meta: {
                permission: 'closed_invoice.show',
                pageTitle: 'تعطیلات',
                pageTitleI18n: 'closed.profile.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id/edit',
              name: 'editClosedInvoice',
              component: () => import('../views/admin/closed/edit/editClosedInvoice.vue'),
              meta: {
                permission: 'closed_invoice.update',
                pageTitle: 'تعطیلات',
                pageTitleI18n: 'closed.edit.title'
                // rule: 'editor'
              }
            },
          ]
        },

        // =============================================================================
        // SALES PAGES LAYOUTS
        // =============================================================================
        {
          path: '/sales',
          component: () => import('../views/admin/sales/index.vue'),
          children: [
            {
              path: '/',
              redirect: '/error-404'
            },
            {
              path: 'invoices',
              component: () => import('../views/admin/sales/invoices/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'saleInvoices',
                  component: () => import('../views/admin/sales/invoices/list/saleInvoicesList.vue'),
                  meta: {
                    permission: 'invoice.show',
                    pageTitle: 'رزرو تایم',
                    pageTitleI18n: 'sales.invoices.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'saleInvoicesTrash',
                  component: () => import('../views/admin/sales/invoices/trash/saleInvoiceTrash.vue'),
                  meta: {
                    permission: 'invoice.delete',
                    pageTitle: 'سطل زباله لیست رزرو',
                    pageTitleI18n: ''
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash/:id',
                  name: 'saleInvoiceTrash',
                  component: () => import('../views/admin/sales/invoices/invoice/saleInvoice.vue'),
                  meta: {
                    permission: 'invoice.delete',
                    pageTitle: 'فاکتور رزرو حذف شده',
                    pageTitleI18n: ''
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertSaleInvoice',
                  component: () => import('../views/admin/sales/invoices/insert/insertTimeSaleInvoice.vue'),
                  meta: {
                    permission: 'invoice.create',
                    pageTitle: 'افزودن فاکتور فروش',
                    pageTitleI18n: 'sales.invoices.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert/past',
                  name: 'insertPastSaleInvoice',
                  component: () => import('../views/admin/sales/invoices/insert/insertTimeSaleInvoice.vue'),
                  meta: {
                    permission: 'invoice.forget',
                    pageTitle: 'ثبت فاکتور رزرو برای گذشته',
                    pageTitleI18n: 'sales.invoices.insert.past.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  name: 'saleInvoice',
                  component: () => import('../views/admin/sales/invoices/invoice/saleInvoice.vue'),
                  meta: {
                    permission: 'invoice.show',
                    pageTitle: 'فاکتور رزرو',
                    pageTitleI18n: 'sales.invoices.invoice.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id/edit',
                  name: 'editSaleInvoice',
                  component: () => import('../views/admin/sales/invoices/edit/editSaleInvoice.vue'),
                  meta: {
                    permission: 'invoice.update',
                    pageTitle: 'ویرایش فاکتور فروش',
                    pageTitleI18n: 'sales.invoices.edit.title'
                    // rule: 'editor'
                  }
                },
              ]
            },
            {
              path: 'season-schedules',
              component: () => import('../views/admin/sales/invoices/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'seasonSchedules',
                  component: () => import('../views/admin/sales/seasonSchedules/list/seasonSchedulesList.vue'),
                  meta: {
                    permission: 'season_scheduler.show',
                    pageTitle: 'رزرو تایم',
                    pageTitleI18n: 'sales.seasonSchedules.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'seasonSchedulesTrash',
                  component: () => import('../views/admin/sales/invoices/trash/saleInvoiceTrash.vue'),
                  meta: {
                    permission: 'season_scheduler.delete',
                    pageTitle: 'سطل زباله لیست رزرو',
                    pageTitleI18n: ''
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash/:id',
                  name: 'seasonScheduleTrash',
                  component: () => import('../views/admin/sales/invoices/invoice/saleInvoice.vue'),
                  meta: {
                    permission: 'season_scheduler.delete',
                    pageTitle: 'فاکتور رزرو حذف شده',
                    pageTitleI18n: ''
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertSeasonScheduleInvoice',
                  component: () => import('../views/admin/sales/seasonSchedules/insert/insertTimeSeasonScheduleInvoice.vue'),
                  meta: {
                    permission: 'season_scheduler.create',
                    pageTitle: 'ثبت فاکتور رزرو فصلی',
                    pageTitleI18n: 'sales.seasonSchedules.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  name: 'seasonSchedule',
                  component: () => import('../views/admin/sales/seasonSchedules/invoice/seasonScheduleInvoice.vue'),
                  meta: {
                    permission: 'season_scheduler.show',
                    pageTitle: 'فاکتور رزرو فصلی',
                    pageTitleI18n: 'sales.seasonSchedules.invoice.title'
                    // rule: 'editor'
                  }
                },
                // {
                //   path: ':id/edit',
                //   name: 'editSaleInvoice',
                //   component: () => import('../views/admin/sales/invoices/edit/editSaleInvoice.vue'),
                //   meta: {
                //     permission: 'invoice.update',
                //     pageTitle: 'ویرایش فاکتور فروش',
                //     pageTitleI18n: 'sales.invoices.edit.title'
                //     // rule: 'editor'
                //   }
                // },
              ]
            },
            {
              path: 'calendar',
              component: () => import('../views/admin/sales/invoices/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'saleCalendar',
                  component: () => import('../views/admin/sales/calendar/salesCalendar.vue'),
                  meta: {
                    permission: 'calendar.show',
                    pageTitle: 'تقویم تایم ها',
                    pageTitleI18n: 'sales.invoices.calendar.title'
                    // rule: 'editor'
                  }
                },
              ]
            },
          ]
        },

        // =============================================================================
        // TREASURY PAGES LAYOUTS
        // =============================================================================
        {
          path: '/treasury',
          component: () => import('../views/admin/treasury/index.vue'),
          children: [
            {
              path: '/',
              redirect: '/error-404'
            },
            {
              path: 'banks',
              component: () => import('../views/admin/treasury/banks/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'treasuryBanks',
                  component: () => import('../views/admin/treasury/banks/list/banksList.vue'),
                  meta: {
                    permission: 'bank.show',
                    pageTitle: 'بانک ها',
                    pageTitleI18n: 'treasury.banks.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryBank',
                  component: () => import('../views/admin/treasury/banks/insert/insertBank.vue'),
                  meta: {
                    permission: 'bank.create',
                    pageTitle: 'افزودن بانک',
                    pageTitleI18n: 'treasury.banks.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'treasuryBankTrash',
                  component: () => import('../views/admin/treasury/banks/trash/banksTrash.vue'),
                  meta: {
                    permission: ['bank.show', 'bank.delete'],
                    pageTitle: 'سطل زباله بانک ها',
                    pageTitleI18n: ''
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  component: () => import('../views/admin/treasury/banks/bank/index.vue'),
                  children: [
                    {
                      path: '/',
                      name: 'treasuryBank',
                      component: () => import('../views/admin/treasury/banks/bank/menu/bankMenu.vue'),
                      meta: {
                        permission: 'bank.show',
                        pageTitle: 'بانک',
                        pageTitleI18n: 'treasury.banks.bank.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'edit',
                      name: 'editTreasuryBank',
                      component: () => import('../views/admin/treasury/banks/bank/edit/editBank.vue'),
                      meta: {
                        permission: 'bank.update',
                        pageTitle: 'ویرایش بانک',
                        pageTitleI18n: 'treasury.banks.edit.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'transactions',
                      name: 'transactionsTreasuryBank',
                      component: () => import('../views/admin/treasury/banks/bank/transactions/bankTransactions.vue'),
                      meta: {
                        permission: 'bank.show',
                        pageTitle: 'گزارشات بانک',
                        pageTitleI18n: ''
                        // rule: 'editor'
                      }
                    }
                    /*{
                      path: 'transactions/:transId',
                      name: 'treasuryBankDocument',
                      component: () => import('../views/admin/documents/document.vue'),
                      meta: {
                        permission: 'bank.show',
                        pageTitle: 'گزارشات بانک',
                        pageTitleI18n: ''
                        // rule: 'editor'
                      }
                    }*/
                  ]
                }
              ]
            },
            {
              path: 'payment-gateways',
              component: () => import('../views/admin/treasury/paymentGateways/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'paymentGateways',
                  component: () => import('../views/admin/treasury/paymentGateways/list/paymentGateways.vue'),
                  meta: {
                    permission: 'payment_gateway.show',
                    pageTitle: 'درگاه های پرداخت'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertPaymentGateway',
                  component: () => import('../views/admin/treasury/paymentGateways/insert/insertPaymentGateway.vue'),
                  meta: {
                    permission: 'payment_gateway.create',
                    pageTitle: 'افزودن درگاه پرداخت'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'trashedPaymentGateways',
                  component: () => import('../views/admin/treasury/paymentGateways/trash/trashedPaymentGateways.vue'),
                  meta: {
                    permission: ['payment_gateway.show', 'payment_gateway.delete'],
                    pageTitle: 'سطل زباله درگاه های پرداخت'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  component: () => import('../views/admin/treasury/paymentGateways/paymentGateway/index.vue'),
                  children: [
                    {
                      path: '/',
                      name: 'paymentGateway',
                      component: () => import('../views/admin/treasury/paymentGateways/paymentGateway/profile/paymentGateway.vue'),
                      meta: {
                        permission: 'payment_gateway.show',
                        pageTitle: 'درگاه پرداخت'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'edit',
                      name: 'editPaymentGateway',
                      component: () => import('../views/admin/treasury/paymentGateways/paymentGateway/edit/editPaymentGateway.vue'),
                      meta: {
                        permission: 'payment_gateway.update',
                        pageTitle: 'ویرایش درگاه پرداخت'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'transactions',
                      name: 'transactionsPaymentGateway',
                      component: () => import('../views/admin/treasury/paymentGateways/paymentGateway/transactions/paymentGatewayTransactions.vue'),
                      meta: {
                        permission: 'payment_gateway.show',
                        pageTitle: 'گزارشات درگاه پرداخت'
                        // rule: 'editor'
                      }
                    }
                  ]
                }
              ]
            },
            {
              path: 'cash_boxes',
              component: () => import('../views/admin/treasury/cashBoxes/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'treasuryCashBoxes',
                  component: () => import('../views/admin/treasury/cashBoxes/list/cashBoxList.vue'),
                  meta: {
                    permission: 'cash.show',
                    pageTitle: 'صندوق ها',
                    pageTitleI18n: 'treasury.cashBoxes.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryCashBox',
                  component: () => import('../views/admin/treasury/cashBoxes/insert/insertCashBox.vue'),
                  meta: {
                    permission: 'cash.create',
                    pageTitle: 'افزودن صندوق',
                    pageTitleI18n: 'treasury.cashBoxes.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'treasuryCashBoxTrash',
                  component: () => import('../views/admin/treasury/cashBoxes/trash/cashBoxesTrash.vue'),
                  meta: {
                    permission: ['cash.show', 'cash.delete'],
                    pageTitle: 'سطل زباله صندوق ها',
                    pageTitleI18n: ''
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  component: () => import('../views/admin/treasury/cashBoxes/cashBox/index.vue'),
                  children: [
                    {
                      path: '/',
                      name: 'treasuryCashBox',
                      component: () => import('../views/admin/treasury/cashBoxes/cashBox/menu/cashBoxMenu.vue'),
                      meta: {
                        permission: 'cash.show',
                        pageTitle: 'صندوق',
                        pageTitleI18n: 'treasury.cashBoxes.cashBox.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'edit',
                      name: 'editTreasuryCashBox',
                      component: () => import('../views/admin/treasury/cashBoxes/cashBox/edit/editCashBox.vue'),
                      meta: {
                        permission: 'cash.update',
                        pageTitle: 'ویرایش صندوق',
                        pageTitleI18n: 'treasury.cashBoxes.edit.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'transactions',
                      name: 'transactionsTreasuryCashBox',
                      component: () => import('../views/admin/treasury/cashBoxes/cashBox/transactions/cashBoxTransactions.vue'),
                      meta: {
                        permission: 'cash.show',
                        pageTitle: 'گزارشات صندوق',
                        pageTitleI18n: ''
                        // rule: 'editor'
                      }
                    }
                    /*{
                      path: 'transactions/:transId',
                      name: 'treasuryCashBoxDocument',
                      component: () => import('../views/admin/documents/document.vue'),
                      meta: {
                        permission: 'cash.show',
                        pageTitle: 'گزارشات صندوق',
                        pageTitleI18n: ''
                        // rule: 'editor'
                      }
                    }*/
                  ]
                }
              ]
            },
            {
              path: 'receive',
              component: () => import('../views/admin/treasury/receive/index.vue'),
              children: [
                {
                  path: '',
                  name: 'treasuryReceive',
                  component: () => import('../views/admin/treasury/receive/list/receivesList.vue'),
                  meta: {
                    pageTitle: 'دریافت',
                    pageTitleI18n: 'treasury.receive.title',
                    permission: 'receive.show'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryReceive',
                  component: () => import('../views/admin/treasury/receive/insert/insertReceive.vue'),
                  meta: {
                    pageTitle: 'دریافت',
                    pageTitleI18n: 'treasury.receive.insert.title',
                    permission: 'receive.create'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  name: 'treasuryReceiveDocument',
                  component: () => import('../views/admin/treasury/receive/document/receiveDocument.vue'),
                  meta: {
                    pageTitle: 'سند دریافت',
                    pageTitleI18n: 'treasury.receive.title',
                    permission: 'receive.show'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'cost',
              component: () => import('../views/admin/treasury/cost/index.vue'),
              children: [
                {
                  path: '',
                  name: 'treasuryCost',
                  component: () => import('../views/admin/treasury/cost/list/costsList.vue'),
                  meta: {
                    permission: 'cost.show',
                    pageTitle: 'هزینه',
                    pageTitleI18n: 'treasury.cost.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryCost',
                  component: () => import('../views/admin/treasury/cost/insert/insertCost.vue'),
                  meta: {
                    permission: 'cost.create',
                    pageTitle: 'هزینه جدید',
                    pageTitleI18n: 'treasury.cost.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  name: 'treasuryCostDocument',
                  component: () => import('../views/admin/treasury/cost/document/costDocument.vue'),
                  meta: {
                    permission: 'cost.show',
                    pageTitle: 'سند هزینه',
                    pageTitleI18n: 'treasury.cost.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'payment',
              component: () => import('../views/admin/treasury/payment/index.vue'),
              children: [
                {
                  path: '',
                  name: 'treasuryPayment',
                  component: () => import('../views/admin/treasury/payment/list/paymentsList.vue'),
                  meta: {
                    pageTitle: 'پرداخت',
                    pageTitleI18n: 'treasury.payment.title',
                    permission: 'payment.show'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryPayment',
                  component: () => import('../views/admin/treasury/payment/insert/insertPayment.vue'),
                  meta: {
                    pageTitle: 'پرداخت جدید',
                    pageTitleI18n: 'treasury.payment.insert.title',
                    permission: 'payment.create'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  name: 'treasuryPaymentDocument',
                  component: () => import('../views/admin/treasury/payment/document/paymentDocument.vue'),
                  meta: {
                    pageTitle: 'سند پرداخت',
                    pageTitleI18n: 'treasury.payment.title',
                    permission: 'payment.show'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'internal-funds-transfers',
              component: () => import('../views/admin/treasury/InternalFundsTransfer/index.vue'),
              children: [
                {
                  path: '',
                  name: 'treasuryInternalFundsTransfers',
                  component: () => import('../views/admin/treasury/InternalFundsTransfer/list/internalFundsTransfersList.vue'),
                  meta: {
                    pageTitle: 'انتقالی خزانه',
                    pageTitleI18n: 'treasury.internalFundsTransfer.title',
                    permission: 'internal_fund.show'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryInternalFundsTransfers',
                  component: () => import('../views/admin/treasury/InternalFundsTransfer/insert/insertInternalFundsTransfer.vue'),
                  meta: {
                    pageTitle: 'انتقالی خزانه جدید',
                    pageTitleI18n: 'treasury.internalFundsTransfer.insert.title',
                    permission: 'internal_fund.create'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  name: 'treasuryInternalFundsTransferDocument',
                  component: () => import('../views/admin/treasury/InternalFundsTransfer/document/internalFundsTransferDocument.vue'),
                  meta: {
                    pageTitle: 'انتقالی خزانه',
                    pageTitleI18n: 'treasury.internalFundsTransfer.title',
                    permission: 'internal_fund.show'
                    // rule: 'editor'
                  }
                },
              ]
            },
            {
              path: 'transactions',
              component: () => import('../views/admin/treasury/transactions/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'treasuryTransactions',
                  component: () => import('../views/admin/treasury/transactions/list/transactionsList.vue'),
                  meta: {
                    pageTitle: 'تراکنش ها',
                    pageTitleI18n: 'treasury.transactions.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryTransaction',
                  component: () => import('../views/admin/treasury/transactions/insert/insertTransaction.vue'),
                  meta: {
                    pageTitle: 'تراکنش ها',
                    pageTitleI18n: 'treasury.transactions.title'
                    // rule: 'editor'
                  }
                }
                /*{
                  path: ':id',
                  name: 'treasuryTransaction',
                  component: () => import('../views/admin/documents/document.vue'),
                  meta: {
                    pageTitle: 'تراکنش',
                    pageTitleI18n: 'treasury.transactions.transaction.title'
                    // rule: 'editor'
                  }
                }*/
              ]
            }
          ]
        },

        // =============================================================================
        // ACCOUNTANCY PAGES LAYOUTS
        // =============================================================================
        {
          path: '/accountancy',
          component: () => import('../views/admin/accountancy/index.vue'),
          children: [
            {
              path: 'types',
              name: 'documentsType',
              component: () => import('../views/admin/accountancy/types/documentsType.vue'),
              meta: {
                permission: 'account.access',
                pageTitle: 'جدول حساب ها',
                pageTitleI18n: 'accountancy.types.title'
                // rule: 'editor'
              }
            },
            {
              path: 'documents',
              component: () => import('../views/admin/accountancy/documents/index.vue'),
              children: [
                {
                  path: '',
                  name: 'documents',
                  component: () => import('../views/admin/accountancy/documents/list/documentsList.vue'),
                  meta: {
                    permission: 'document.access',
                    pageTitle: 'لیست اسناد',
                    pageTitleI18n: 'accountancy.documents.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertDocument',
                  component: () => import('../views/admin/accountancy/documents/insert/insertDocument.vue'),
                  meta: {
                    permission: 'document.access',
                    pageTitle: 'افزودن سند دریافت/پرداخت',
                    pageTitleI18n: ''
                    // rule: 'editor'
                  }
                },
                {
                  path: 'doc-:id',
                  name: 'document',
                  component: () => import('../views/admin/accountancy/documents/document/document.vue'),
                  meta: {
                    permission: 'document.access',
                    pageTitle: 'سند',
                    pageTitleI18n: 'accountancy.documents.document.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'opening-balance',
              name: 'openingBalance',
              component: () => import('../views/admin/accountancy/openingBalance/index.vue'),
              meta: {
                permission: 'opening.access',
                pageTitle: 'تراز افتتاحیه',
                pageTitleI18n: 'accountancy.openingBalance.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // COMPANY PAGES LAYOUTS
        // =============================================================================
        {
          path: 'companies',
          component: () => import('../views/admin/companies/index.vue'),
          children: [
            {
              path: '/',
              name: 'companies',
              component: () => import('../views/admin/companies/list/companiesList.vue'),
              meta: {
                pageTitle: 'لیست شرکت ها',
                pageTitleI18n: 'companies.title'
                // rule: 'editor'
              }
            },
            {
              path: 'insert',
              name: 'insertCompany',
              component: () => import('../views/admin/companies/insert/insertCompany.vue'),
              meta: {
                pageTitle: 'افزودن شرکت',
                pageTitleI18n: 'companies.insert.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id',
              name: 'company',
              component: () => import('../views/admin/companies/company/company.vue'),
              meta: {
                pageTitle: 'باشگاه',
                pageTitleI18n: 'companies.company.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id/edit',
              name: 'editCompany',
              component: () => import('../views/admin/companies/edit/editCompany.vue'),
              meta: {
                pageTitle: 'ویرایش شرکت',
                pageTitleI18n: 'companies.edit.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // BACKUP PAGES LAYOUTS
        // =============================================================================
        {
          path: 'backup',
          name: 'backup',
          component: () => import('../views/admin/backup/backup.vue'),
          meta: {
            permission: 'backup.show',
            pageTitle: 'پشتیبان گیری',
            pageTitleI18n: 'backup.title'
            // rule: 'editor'
          }
        },

        // =============================================================================
        // TICKETS PAGES LAYOUTS
        // =============================================================================
        {
          path: '/tickets',
          component: () => import('../views/admin/tickets/index.vue'),
          children: [
            {
              path: 'support',
              component: () => import('../views/admin/tickets/supportTicket/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'supportTickets',
                  component: () => import('../views/admin/tickets/supportTicket/list/ticketsSupportList.vue'),
                  meta: {
                    permission: 'support_ticket.show',
                    pageTitle: 'تیکت ها',
                    pageTitleI18n: 'tickets.support.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertSupportTicket',
                  component: () => import('../views/admin/tickets/supportTicket/insert/insertSupportTicket.vue'),
                  meta: {
                    permission: 'support_ticket.create',
                    pageTitle: 'افزودن تیکت جدید',
                    pageTitleI18n: 'tickets.support.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  name: 'supportTicketDetail',
                  component: () => import('../views/admin/tickets/supportTicket/profile/ticketSupportDetail.vue'),
                  meta: {
                    permission: 'support_ticket.show',
                    pageTitle: '',
                    pageTitleI18n: 'tickets.support.detail.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'organization',
              component: () => import('../views/admin/tickets/organizationTicket/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'organizationTickets',
                  component: () => import('../views/admin/tickets/organizationTicket/list/ticketsOrganizationList.vue'),
                  meta: {
                    permission: 'organizational_ticket.show',
                    pageTitle: 'تیکت ها',
                    pageTitleI18n: 'tickets.organization.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertOrganizationTicket',
                  component: () => import('../views/admin/tickets/organizationTicket/insert/insertOrganizationTicket.vue'),
                  meta: {
                    permission: 'organizational_ticket.create',
                    pageTitle: 'افزودن تیکت جدید',
                    pageTitleI18n: 'tickets.organization.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  name: 'organizationTicketDetail',
                  component: () => import('../views/admin/tickets/organizationTicket/profile/ticketOrganizationDetail.vue'),
                  meta: {
                    permission: 'organizational_ticket.show',
                    pageTitle: '',
                    pageTitleI18n: 'tickets.organization.detail.title'
                    // rule: 'editor'
                  }
                }
              ]
            }
          ]
        },

        // =============================================================================
        // SETTING PAGES LAYOUTS
        // =============================================================================
        {
          path: '/settings',
          component: () => import('../views/admin/settings/index.vue'),
          children: [
            {
              path: '/',
              name: 'Settings',
              component: () => import('../views/admin/settings/setting/settingMenu.vue'),
              meta: {
                pageTitle: 'تنظیمات',
                pageTitleI18n: 'setting.title'
                // rule: 'editor'
              }
            },
            {
              path: 'access',
              component: () => import('../views/admin/settings/access/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'accessGroupsList',
                  component: () => import('../views/admin/settings/access/list/accessGroupsList.vue'),
                  meta: {
                    permission: 'role.show',
                    pageTitle: 'تنظیمات گروه های کاربری',
                    pageTitleI18n: 'setting.access.title'
                    // rule: 'editor'
                  }
                }
                /*{
                  path: 'insert',
                  name: 'insertAccessGroup',
                  component: () => import('../views/admin/settings/access/insert/insertAccessGroup.vue'),
                  meta: {
                    pageTitle: 'افزودن گروه کاربری جدید',
                    pageTitleI18n: 'setting.access.insert.title',
                    // rule: 'editor'
                  },
                },
                {
                  path: ':id/edit',
                  name: 'editAccessGroup',
                  component: () => import('../views/admin/settings/access/edit/editAccessGroup.vue'),
                  meta: {
                    pageTitle: 'ویرایش گروه کاربری',
                    pageTitleI18n: 'setting.access.edit.title',
                    // rule: 'editor'
                  },
                },*/
              ]
            },
            {
              path: 'buy',
              name: 'buySetting',
              component: () => import('../views/admin/settings/buy/BuySettings.vue'),
              meta: {
                pageTitle: 'تنظیمات خرید',
                pageTitleI18n: 'setting.Buy.title'
                // rule: 'editor'
              }
            },
            {
              path: 'sale',
              name: 'saleSetting',
              component: () => import('../views/admin/settings/sale/SaleSettings.vue'),
              meta: {
                pageTitle: 'تنظیمات فروش',
                pageTitleI18n: 'setting.Sale.title'
                // rule: 'editor'
              }
            },
            {
              path: 'languages',
              name: 'languagesSetting',
              component: () => import('../views/admin/settings/locale/LocaleSetting.vue'),
              meta: {
                pageTitle: 'زبان ها',
                pageTitleI18n: 'setting.Locale.title'
                // rule: 'editor'
              }
            },
            {
              path: 'users',
              name: 'usersSetting',
              component: () => import('../views/admin/settings/users/usersSetting.vue'),
              meta: {
                pageTitle: 'تنظیمات اشخاص',
                pageTitleI18n: 'setting.users.title'
                // rule: 'editor'
              }
            },
            {
              path: 'security',
              name: 'securitySetting',
              component: () => import('../views/admin/settings/security/securitySetting.vue'),
              meta: {
                pageTitle: 'امنیت',
                pageTitleI18n: 'setting.security.title'
                // rule: 'editor'
              }
            },
            {
              path: 'customizing',
              name: 'customizeSetting',
              component: () => import('../views/admin/settings/customize/customizeSetting.vue'),
              meta: {
                pageTitle: 'شخصی سازی سامانه',
                pageTitleI18n: 'setting.customize.title'
                // rule: 'editor'
              }
            },
            {
              path: 'treasury',
              name: 'treasurySetting',
              component: () => import('../views/admin/settings/treasury/TreasurySettings.vue'),
              meta: {
                pageTitle: 'تنظیمات خزانه',
                pageTitleI18n: 'setting.Treasury.title'
                // rule: 'editor'
              }
            },
            {
              path: 'financial-year',
              name: 'financialYearSetting',
              component: () => import('../views/admin/settings/financialYear/list/financialYearList.vue'),
              meta: {
                permission: 'financial_year.show',
                pageTitle: 'تنظیمات سال مالی',
                pageTitleI18n: 'setting.financialYear.title'
                // rule: 'editor'
              }
            },
            {
              path: 'store',
              name: 'storeSetting',
              component: () => import('../views/admin/settings/store/StoreSettings.vue'),
              meta: {
                pageTitle: 'تنظیمات انبار',
                pageTitleI18n: 'setting.Store.title'
                // rule: 'editor'
              }
            },
            {
              path: 'advanced',
              name: 'advancedSetting',
              component: () => import('../views/admin/settings/advanced/advancedSetting.vue'),
              meta: {
                pageTitle: 'تنظیمات پیشرفته',
                pageTitleI18n: 'setting.Store.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // SETTING PAGES LAYOUTS
        // =============================================================================
        {
          path: '/reports',
          component: () => import('../views/admin/reports/index'),
          children: [
            // {
            //   path: 'advance',
            //   name: 'advanceReport',
            //   component: () => import('../views/admin/reports/advancReport/list/advanceReports.vue'),
            //   meta: {
            //     permission: 'report.show',
            //     pageTitle: 'گزارشات من',
            //     pageTitleI18n: 'reports.title'
            //     // rule: 'editor'
            //   }
            // },
            {
              path: 'user-report',
              name: 'userReport',
              component: () => import('../views/admin/reports/userReport/userReport.vue'),
              meta: {
                permission: 'report.show',
                pageTitle: 'گزارشات شخص',
                pageTitleI18n: 'report.userReport.title'
                // rule: 'editor'
              }
            },
            {
              path: 'sale-details',
              name: 'saleDetailReport',
              component: () => import('../views/admin/reports/saleDetailReport/saleDetailReport.vue'),
              meta: {
                permission: 'report.show',
                pageTitle: 'گزارشات فروش',
                pageTitleI18n: 'report.saleDetail.title'
                // rule: 'editor'
              }
            },
            {
              path: 'coaches',
              name: 'coachesReport',
              component: () => import('../views/admin/reports/coach/coachesReport.vue'),
              meta: {
                permission: 'report.show',
                pageTitle: 'گزارش درآمد و هزینه مربی',
                pageTitleI18n: 'report.coach.title'
                // rule: 'editor'
              }
            },
            {
              path: 'sale-addon-details',
              name: 'saleAddonDetailReport',
              component: () => import('../views/admin/reports/saleAddonDetailReport/saleAddonDetailsReport.vue'),
              meta: {
                permission: 'report.show',
                pageTitle: 'گزارشات فروش افزودنی',
                pageTitleI18n: 'report.saleAddonDetail.title'
                // rule: 'editor'
              }
            },
            {
              path: 'advance/:id',
              name: 'reportDetail',
              component: () => import('../views/admin/reports/advancReport/report/index.vue'),
              meta: {
                pageTitle: 'گزارشات',
                pageTitleI18n: 'reports.title'
                // rule: 'editor'
              }
            }
            // {
            //   path: 'activities-log',
            //   name: 'activitiesLog',
            //   component: () => import('../views/admin/reports/activitiesLog/usersActivitiesLog.vue'),
            //   meta: {
            //     permission: 'log.show',
            //     pageTitle: 'لیست لاگ ها',
            //     pageTitleI18n: ''
            //     // rule: 'editor'
            //   }
            // },
            // {
            //   path: 'users-total-sales',
            //   name: 'usersTotalSalesReport',
            //   component: () => import('../views/admin/reports/usersTotalSales/usersTotalSalesReport.vue'),
            //   meta: {
            //     pageTitle: 'مجموع فروش به اشخاص',
            //     pageTitleI18n: 'reports.usersTotalSales.title'
            //     // rule: 'editor'
            //   }
            // }
          ]
        }

        /*{
          path: '/test',
          name: 'test',
          component: () => import('../views/pages/test'),
          meta: {
            pageTitle: 'test',
            pageTitleI18n: ''
            // rule: 'editor'
          },
        },*/
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'page-login',
          component: () => import('@/views/pages/Login.vue')
        },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        },
        {
          path: '/sales/invoices/:id/print',
          name: 'print-sale-invoice-page',
          component: () => import('@/views/admin/sales/invoices/print/printInvoice.vue')
        },
        {
          path: '/treasury/payment/:id/print',
          name: 'print-payment-page',
          component: () => import('@/views/admin/accountancy/documents/print/PrintPayment.vue')
        },
        {
          path: '/treasury/receive/:id/print',
          name: 'print-receive-page',
          component: () => import('@/views/admin/accountancy/documents/print/PrintReceive.vue')
        },
        {
          path: '/treasury/internal-funds-transfers/:id/print',
          name: 'print-internal-funds-transfer-page',
          component: () => import('@/views/admin/accountancy/documents/print/PrintInternalFundsTransfer.vue')
        },
        {
          path: '/treasury/cost/:id/print',
          name: 'print-cost-page',
          component: () => import('@/views/admin/accountancy/documents/print/PrintCost.vue')
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404'
    }
  ]
})

router.afterEach(afterEach)

export default router

router.beforeEach(beforeEach)
